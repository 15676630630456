#sidebar-wrapper {
    margin-left: 0;
    min-height: 100vh;
    transition: margin 0.25s ease-out;
    background-color: $sidebar-color;

    .sidebar-heading {
        padding: .5rem 1.25rem 1rem 1.25rem;
        font-size: 48px;
        color: $white;
        text-align: center;
        font-weight: 600;
    }

    .list-group {
        width: 17rem;
        margin-left: 2rem;

        .list-group-item {
            color: $white;
            font-size: 20px;
            font-weight: 500;
            margin: .8rem 0;
            padding-left: 1.5rem !important;
            border-radius: 33px 0 0 33px;
            background-color: $sidebar-color;

            i {
                width: 30px;
            }

            &.active,
            &:hover {
                background-color: $white;
                color: $sidebar-color;
            }
        }
    }
}

#page-content-wrapper {
    overflow-y: auto;
    width: 100%;

    main {
        width: calc(100% - 1.5rem - 1.5rem);
    }
}

button#toggleSidebar {
    padding: 0.5rem 1rem;
    i {
        font-size: 28px;
        color: $dark;
    }
}

@media (max-width: 991px) {
    #page-content-wrapper {
        main {
            padding-right: 0 !important;
            padding-left: 0 !important;
        }
    }

    #sidebar-wrapper {
        display: none;
    }

    body.sidebar-toggled {
        #sidebar-wrapper {
            z-index: 9000;
            display: block;
            position: fixed;
            top: 0; right: 0; bottom: 0; left: 0;
            margin-left: 0;

            .sidebar-heading {
                margin-top: 1rem;
            }
            .list-group {
                width: 100%;
                padding: 0 16px;
                margin-left: 0;

                .list-group-item {
                    border-radius: 33px;
                }
            }
        }

        button#toggleSidebar {
            z-index: 9999;
            i {
                color: #fff;
            }
        }
    }
}

// DANBOARD Navbar items
#danboardNavItems {
    .nav-link {
        color: $black;

        &.active {
            color: $white;
            background-color: #4646AA;
        }
    }
}
