// Import Roboto font
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

// Set standards
$font-family-sans-serif: 'Roboto', sans-serif;
$body-bg: #F9F9FB;
$body-color: #3B3B3B;

// Variables
$sidebar-color: #4646AA;

// Colors
$primary:       #7848F4;
$secondary:     #C748F4;
$success:       #48F487;
$info:          #4898F4;
$warning:       #F4C648;
$danger:        #F44848;
$light:         #F8F9FA;
$dark:          #212529;

// Buttons
button:not([class*="btn-outline-"]) {
    color: #fff !important;
}

// Form inputs
input:not([type=checkbox]) {
    background-color: #fff !important;
}

// Responsive width
.w-md-80 {
    width: 80%;
}
@media (max-width: 991px) {
    .w-md-80 {
        width: 100%;
    }
}

// Responsive search field
.search-field {
    max-width: 300px;
}

// Primary dark
.text-primary-dark {
    color: #4646AA;
}

// Background danboard
.bg-danboard {
    background-color: #F3F3FF;
}

// Danboard container
.container-fluid.container-danboard {
    width: calc(100% - 32px - 32px);
}

@media (max-width: 991px) {
    .container-fluid.container-danboard {
        width: calc(100% - 16px);
    }
}

// Max content
.max-content {
    width: max-content !important;
}
