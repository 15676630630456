.btn-gray {
    background-color: #6c757d;
    border-color: #6c757d;

    &:hover {
        background-color: #5c636a;
        border-color: #565e64;
    }

    &:focus {
        box-shadow: rgb(130,138,145);
    }

    &:active {
        background-color: #565e64;
        border-color: #51585e;
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }

    &:disabled {
        background-color: #6c757d;
        border-color: #6c757d;
    }
}
