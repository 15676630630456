input[type="date"][value=""],
input[type="time"][value=""],
input[type="date"][value],
input[type="time"][value] {
    color: #fff;
    &::before {
        content: attr(placeholder);
        position: absolute;
        color: #6C757D;
    }

    &:focus,
    &:valid {
        color: #3B3B3B;
    }

    &:focus::before,
    &:valid::before {
        content: "";
    }
}

.form-switch-flip {
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
}
