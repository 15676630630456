#empty-background {
    background-color: #F3F3FF;
}

@media only screen and (min-width: 450px) {
    #login-form {
        max-width: 470px;
        min-width: 400px;
        min-height: 350px;

        #login-logo {
            height: 100px;
        }
    }
}

@media only screen and (max-width: 450px) {
    #login-form {
        width: calc(100vw - 16px - 16px);

        #login-logo {
            height: 70px;
        }
    }
}
