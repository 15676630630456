// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Sidebar
@import "sidebar";

// Event cards
@import "event-cards";

// Event page
@import "event";

// Tableyouclub login page
@import "login";

// Tables
@import "tables";

// Danboard navbar
@import "navbar";

// Inputs
@import "inputs";

// Buttons
@import "buttons";
