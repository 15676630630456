#toggleNavbar {
    z-index: 9999;
}

#topbar.navbar-toggled {
    z-index: 9000;
    display: block;
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    background-color: $white;
    overflow-y: auto;

    .navbar-nav {
        width: max-content !important;
        margin: 32px auto !important;

        .nav-item {
            margin: 4px 0;
        }
    }
}
