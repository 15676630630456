.event-image-wrapper {
    z-index: -1;
    position: relative;
    width: 100%;
    height: 400px;
    border-radius: 8px;

    .event-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
    }

    h1{
        color: $white;
        font-size: 80px;
        font-weight: bold;
        position: absolute;
        left: 1rem; top: 50%;
        transform: translateY(-50%);

        @media (max-width: 768px) {
            font-size: 50px;
        }
    }
}

.event-info {
    z-index: 2;
    background-color: #0B0434;
    //display: flex;
    //justify-content: space-around;
    color: $white;
    padding: 2rem;
    //width: 80%;
    border-radius: 16px;
    margin: -50px auto 0 auto;

    .event-info-item {
        display: flex;
        align-items: center;
        gap: 1rem;

        i {
            font-size: 40px;
        }
        p {
            margin-bottom: 0;
            font-weight: bold;
        }
    }
}

.btn-list {
    //display: flex;
    //justify-content: space-between;
    align-items: start;
    //width: 80%;
    margin: 0 auto;
}
