.card.event-card {
    .event-price {
        background-color: $white;
        position: absolute;
        left: 1rem; top: 1rem;
        padding: .5rem;
        border-radius: 8px;
        font-weight: 600;
    }
    .event-heart {
        background-color: $white;
        position: absolute;
        right: 1rem; top: 1rem;
        padding: .5rem .5rem .4rem .5rem;
        border-radius: 50%;

        i {
            color: #FF4040;
            font-size: 22px;
            width: 26px;
            height: 18px;
            text-align: center;
        }
    }

    .card-body {
        display: flex;
        gap: 1rem;

        .event-date {
            display: flex;
            flex-direction: column;

            .event-date-month {
                font-size: 13px;
                font-weight: bold;
                color: #4646AA;
            }
            .event-date-day {
                font-size: 24px;
                font-weight: 500;
            }
        }

        .event-card-info {
            .event-info-desc {
                color: #8F8F8F;
                margin-bottom: 0;
            }
        }
    }
}
