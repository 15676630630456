// Data table
.table.table-p-left tr td:first-child,
.table.table-p-left tr th:first-child {
    padding-left: 1.5rem;
}

.table.data-table tr td {
    padding: 0.8rem 0.5rem;
}

// Lg table
.table.table-lg tr td,
.table.table-lg tr th {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
